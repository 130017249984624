import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import logo from "../images/header-logo.svg"
import { Button } from './'
import "./Header.css"
import MediaQuery from 'react-responsive'

const Header = ({ notHome }) => {
    useEffect(() => {
        console.log("isHome :" + !notHome)
    }, [notHome]);

    const history = useHistory()
    return (
        <>
            <MediaQuery minWidth={820}>
                <div className='header-parent'>
                    <header className={`header ${notHome && "not-home-header"}`}>
                        <ul class="nav-list">
                            <li class="nav-list-item" onClick={() => { history.push("/"); document.getElementById("about").scrollIntoView(true) }}>ABOUT</li>
                            <li class="nav-list-item" onClick={() => { history.push("/"); document.getElementById("access").scrollIntoView(true) }}>ACCESS</li>
                            <li class="nav-list-item" onClick={() => { history.push("/"); document.getElementById("supporter").scrollIntoView(true) }}>SUPPORTER</li>
                            <li>
                                <h1 class="header-headline">
                                    <a><img src={logo} alt="名古屋大学交響楽団" /></a>
                                </h1>
                            </li>
                            <li class="nav-list-item" onClick={() => { history.push("/news") }}>BLOG</li>
                            <li class="nav-list-item" onClick={() => { history.push("/"); document.getElementById("contact").scrollIntoView(true) }}>CONTACT</li>
                            <li className='nav-list-button' onClick={() => { history.push("/tickets") }}><Button text="GET TICKETS" /> </li>
                        </ul>
                    </header>
                </div >
            </MediaQuery>
        </>
    )
}

export default Header