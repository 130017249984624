import React from 'react'
import { NewsCard } from "../"
import "./News.css"
import instagram from "../../images/instagram.svg"
import twitter from "../../images/twitter.svg"
import { newsList } from '../newsList'
import { Link } from 'react-router-dom'

const News = () => {
    return (
        <div className='news-section'>
            <h2 className='news-section-title'>
                NEWS
            </h2>
            <div className='icons'>
                <a href="https://twitter.com/NUSO_official" target={"_blank"}><img src={twitter} alt="" width={"50px"} /></a>
                <a href="https://www.instagram.com/nuso_official/" target={"_blank"}><img src={instagram} alt="" width={"50px"} /></a>
            </div>
            <div className="news-cards">
                {newsList.map((data, index) => {
                    if (index <= 2)
                        return (
                            <div className='card'>
                                <NewsCard src={data.image}
                                    title={data.title} auther={data.author} date={data.date} text={data.content} link={`/news/${data.link}`} />
                            </div>
                        )
                })}
            </div>
            <Link to="/news" className='view-more'>
                VIEW MORE
            </Link>
        </div>
    )
}

export default News