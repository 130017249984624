import { Divider } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import { useEffect } from 'react'
import "./Guideline.css"

const Guideline = ({ setIsHome }) => {

    useEffect(() => {
        setIsHome(false)
    }, []);

    return (
        <div className='guideline-container'>
            <Container>
                <div className="guideline-title">
                    <h2>
                        感染症対策ガイドライン
                    </h2>
                </div>
                <div className='dont'>
                    <p>以下の場合はご入場をご遠慮ください。</p>
                    <ul>
                        <li>37.5℃以上の発熱がある場合</li>
                        <li>咳・咽頭痛などの症状のある場合</li>
                        <li>新型コロナウイルス感染症陽性とされた者・陽性疑いのある者との濃厚接触がある場合</li>
                        <li>過去2週間以内に入国制限、入国後の観察期間を必要とされている国・地域への訪問歴及び当該在住者との濃厚接触がある場合　等</li>
                    </ul>
                </div>
                <Divider sx={{ mt: "2rem" }} />
                <div className='please'>
                    <p>〈ご来場の皆様へのお願い〉</p>
                    <ul>
                        <li>ご来場前の検温にご協力ください。体温が37.5℃以上の場合や体調がすぐれない場合はご来場をお控えください。当日会場入口にて検温を行います。その際に37.5℃以上であった場合、ご入場をお断りさせていただきます。</li>
                        <li>ご来場の際はマスクをご着用ください。未着用の方は、ご入場をお断りさせていただく場合がございます。また大声を出さないなど飛沫の抑制をお願いいたします。</li>
                        <li>ご来場の際は手指のアルコール消毒にご協力ください。</li>
                        <li>列に並ばれる際やご退場時は、他のお客様との間に距離をとっていただくようお願いいたします。</li>
                        <li>会場内での飲食はお控えください。</li>
                        <li>チケットをお持ちのお客様は、事前に必要事項をご記入の上ご来場ください。</li>
                        <li>終演後の時差退場にご協力をお願いします。退場時はアナウンスにて順番をご案内いたします。アナウンスにそって速やかにお帰りください。</li>
                        <li>会場内は空調にて十分な換気を行うため、客席内温湿度が一定になりにくいことがあります。温度調整が可能な服装でお越しください。</li>
                    </ul>
                </div>
                <Divider sx={{ mt: "2rem" }} />
                <div className='guideline'>
                    <p>〈感染防止策について〉</p>
                    <ul>
                        <li>会場内は常時空調にて換気を行っております。</li>
                        <li>ご来場のお客様には、入場時に手指のアルコール消毒および検温をさせていただきます。</li>
                        <li>ステージ上では互いに距離をとって演奏いたします。</li>
                    </ul>
                </div>
            </Container>
        </div>
    )
}

export default Guideline