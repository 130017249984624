import React from 'react'
import logo from "../images/footer-logo.svg"
import "./Footer.css"
import { Button } from "./"
import twitter from "../images/twitter.svg"
import instagram from "../images/instagram.svg"
import MediaQuery from 'react-responsive'
import { useHistory } from 'react-router-dom'

const Footer = () => {

    const history = useHistory()

    return (

        <div className='footer'>
            <img className='footer-logo' src={logo} alt="" />
            <footer>
                <ul class="nav-list">
                    <li class="nav-list-item" onClick={() => { history.push("/"); document.getElementById("about").scrollIntoView(true) }}>ABOUT</li>
                    <li class="nav-list-item" onClick={() => { history.push("/"); document.getElementById("access").scrollIntoView(true) }}>ACCESS</li>
                    <li class="nav-list-item" onClick={() => { history.push("/"); document.getElementById("supporter").scrollIntoView(true) }}>SUPPORTER</li>
                    <li class="nav-list-item" onClick={() => { history.push("/news") }}>BLOG</li>
                    <li class="nav-list-item" onClick={() => { history.push("/"); document.getElementById("contact").scrollIntoView(true) }}>CONTACT</li>
                    <li className='nav-list-button'><Button text="GET TICKETS" black /> </li>
                </ul>
            </footer>
            <div className="icons">
                <a href="https://twitter.com/NUSO_official" target="_blank"><img src={twitter} alt="" /></a>
                <a href="https://www.instagram.com/nuso_official/" target="_blank"><img src={instagram} alt="" /></a>
            </div>
            <div className="copy">(C) Copyright Nagoya University Symphony Orchestra 2022 All rights reserved.</div>
        </div>
    )
}

export default Footer