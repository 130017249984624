import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "./Program.css"

const Program = () => {

    const programList = [{ composer: "ベルリオーズ", music: "幻想交響曲　作品14", en: "H. Berlioz : Symphonie Fantastique Op.14" },
    { composer: "リスト", music: "交響詩「レ・プレリュード」", en: "F. Liszt : Les Préludes" },
    { composer: "サン=サーンス", music: "歌劇「サムソンとデリラ」よりバッカナール", en: "C. Saint-Saëns : Bacchanale de Samson et Dalila" },]

    return (
        <div className='program-section' >
            <div className='program'>
                {programList.map((data, index) => {
                    return (
                        <div className='item'>
                            <AnimationOnScroll key={index} animateIn="fadeIn">
                                <p className='composer'>{data.composer}</p>
                                <p className='music'>{data.music}</p>
                                <p className='en'>{data.en}</p>
                            </AnimationOnScroll>
                        </div>
                    )
                })}
            </div>
            <div className='overview'>
                <p className='conductor'>指揮：篠﨑靖男</p>
                <p className='date' >2022年 12月18日 (日)</p>
                <p>16:30開場/17:30開演</p>
                <p>刈谷市総合文化センターアイリス　大ホール</p>
                <p className='detail'>前売り券 800円　10月初旬より発売開始</p>
            </div>
        </div>

    )
}

export default Program