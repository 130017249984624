import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { Footer, Guideline, HandsUp, Header, Home, NewsList, NewsPage, Sidebar, Tickets } from './components';
import React, { useState } from 'react';
import ScrollToTop from './components/ScrollToTop';
import { Alert, Collapse } from '@mui/material';

function App() {

  const [isHome, setIsHome] = useState(false);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Header notHome={!isHome} />
        <Sidebar />
        

        <Switch>
          <Route exact path={"/"}>
            <Home setIsHome={setIsHome} />
          </Route>
          <Route path={"/guideline"}>
            <Guideline setIsHome={setIsHome} />
          </Route>
          <Route path={"/hands-up"}>
            <HandsUp setIsHome={setIsHome} />
          </Route>
          <Route path={"/tickets"} >
            <Tickets setIsHome={setIsHome} />
          </Route>
          <Route exact path={`/news`}>
            <NewsList setIsHome={setIsHome} />
          </Route>
          <Route path={`/news/:id`} >
            <NewsPage />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
