import { Container, Divider } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import "./Tickets.css"

const Tickets = ({ setIsHome }) => {

    useEffect(() => {
        setIsHome(false)
    }, []);

    return (
        <div className='tickets-container'>
            <Container>
                <div className='tickets-title'>
                    <h2>チケット</h2>
                    <p>前売り券800円　当日券900円　10月1日より発売開始</p>
                </div>
                <table className="cp_table">
                    <tr>
                        <th>チケットぴあ</th>
                        <td>
                            <a href="http://ticket.pia.jp/pia/event.ds?eventCd=2232515" target={"_blank"} className='btn06 btnarrow1'>チケット情報</a>
                            <p className='caption'>(Pコード：227-072)</p>
                        </td>
                    </tr>
                    <tr>
                        <th>愛知県芸術文化センタープレイガイド</th>
                        <td>
                            <p>Tel: 052-972-0430</p>
                        </td>
                    </tr>
                    <tr>
                        <th>刈谷市総合文化センター窓口</th>
                        <td>
                            <p>Tel: 0566-21-7430</p>
                            <p className='caption'>窓口での受付のみ</p>
                        </td>
                    </tr>
                    <tr>
                        <th>名古屋大学生協</th>
                        <td>
                            <p>東山地区南部購買
                                Tel: 052-781-1249</p>
                            <p>鶴舞地区医学部購買
                                Tel: 052-732-5090</p>
                        </td>
                    </tr>
                </table>
                <p className="info">
                    ※チケットぴあの電話予約サービスは昨年末で終了しました。インターネットまたはセブンイレブンの端末からお求めください。<br />
                    ※当日券（900円）も販売を予定しておりますが、予告なく終了となる場合がありますのでご注意ください。
                </p>
                <Divider />
                <div className='please'>
                    <h2>〈お客様情報の提供にご協力お願いいたします〉</h2>
                    <p>チケットの所定の欄に氏名・住所・電話番号を記入のうえご来場ください。チケットぴあで購入された方は、ご来場時に当団が用意するチケットと交換いたしますので、その際にご記入ください。なお、チケットに記入していただいた情報は、感染者が確認された場合に保健所等の公的機関に提供させていただくことがあります。それ以外の目的には使用せず、当団で一定期間保管したのち責任をもって処分いたします。</p>
                </div>
            </Container>
        </div>
    )
}

export default Tickets