import React, { useEffect } from 'react'
import { Hero, News, About, Conductor, Access, Contact, Program, Supporter, SPHero } from '../'
import MediaQuery from 'react-responsive';
import { Alert, Collapse, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Home = ({ setIsHome }) => {

    useEffect(() => {
        setIsHome(true)
    }, []);

    const [isAlertOpened, setIsAlertOpened] = useState(true);
    const history = useHistory()

    return (
        <>
            <Snackbar open={isAlertOpened} onClose={() => { setIsAlertOpened(false) }}>
                <Alert severity="info" onClose={() => { setIsAlertOpened(false) }}><p onClick={() => { history.push("/hands-up") }} style={{padding:0, margin:0, cursor:"pointer"}}>第123回定期演奏会にご来場されるお客様はご確認ください</p></Alert>
            </Snackbar>
            <MediaQuery minWidth={820}>
                <Hero />
                <Program />
                <News />
                <About />
                <Conductor />
                <Access />
                <Contact />
                <Supporter />
            </MediaQuery>
            <MediaQuery maxWidth={819}>
                <SPHero />
                <Program />
                <News />
                <About />
                <Conductor />
                <Access />
                <Contact />
                <Supporter />
            </MediaQuery>
        </>
    )
}

export default Home