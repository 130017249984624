import React, { useState } from 'react'
import "./Sidebar.css"
import { Button } from "./"
import logo from "../images/side-logo.svg"
import { useHistory } from 'react-router-dom'

const Sidebar = () => {
    const [open, setOpen] = useState(false);
    const history = useHistory()

    return (
        <div id="navArea" className={open && "open"}>

            <nav>
                <div class="inner">
                    <img src={logo} alt="" height={40} />
                    <ul>
                        <li onClick={() => { history.push("/"); document.getElementById("access").scrollIntoView(true); setOpen(false) }}><p style={{cursor:"pointer"}}>ACCESS</p></li>
                        <li onClick={() => { history.push("/"); document.getElementById("about").scrollIntoView(true); setOpen(false) }}><p style={{ cursor: "pointer" }}>ABOUT</p></li>
                        <li onClick={() => { history.push("/"); document.getElementById("supporter").scrollIntoView(true); setOpen(false) }}><p style={{ cursor: "pointer" }}>SUPPORTER</p></li>
                        <li onClick={() => { history.push("/news"); setOpen(false) }}><p style={{ cursor: "pointer" }}>BLOG</p></li>
                        <li onClick={() => { history.push("/"); document.getElementById("contact").scrollIntoView(true); setOpen(false) }}><p style={{ cursor: "pointer" }}>CONTACT</p></li>
                        <li onClick={() => { history.push("/hands-up") }}><p style={{ fontSize: "1rem", cursor:"pointer" }}>注意事項</p></li>
                        <li onClick={() => { history.push("/guideline") }}><p style={{ fontSize: "1rem", cursor: "pointer" }}>感染予防ガイドライン</p></li>
                        <li onClick={() => { history.push("/tickets") }}><p><Button text="GET TICKETS" black ></Button></p></li>
                    </ul>
                </div>
            </nav>

            <div class="toggle_btn" onClick={() => { setOpen(!open) }}>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div id="mask" onClick={() => { setOpen(false) }}></div>

        </div>
    )
}

export default Sidebar