import { Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import NewsCard from "../NewsCard"
import { newsList } from '../newsList';
import "./NewsList.css"

const NewsList = ({ setIsHome, match }) => {

    useEffect(() => {
        setIsHome(false)
    }, []);

    return (
        <div className='news-list-container' style={{ paddingTop: "10rem", paddingBottom: "10rem" }}>
            <Container>
                <h2 className='news-text'>NEWS</h2>
                <Grid container spacing={5}>
                    {newsList.map((data, index) => {
                        return (
                            <Grid key={index} xs={12} sm={6} md={4} item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <NewsCard src={data.image}
                                    title={data.title} auther={data.author} date={data.date} text={data.content} link={`/news/${data.link}`} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </div>
    )
}

export default NewsList