import React from 'react'
import image from "../../images/nuso-image.jpeg"
import "./About.css"

const About = () => {
    return (
        <div className="about-section" id='about'>
            <div className="img"></div>
            <h2 className="title">名古屋大学交響楽団</h2>
            <div className="text">
                名古屋大学交響楽団は、1923年から学内で行われていた戦前の管弦楽運動の再興を目指し、若井一朗氏らが中心となって、1954年名古屋大学管弦楽団として発足。以降毎年春と冬に定期演奏会を行っている。夏には4日間にわたって中部地方の小中学校を訪問し直接オーケストラ演奏を届ける音楽教室を実施していたが、近年は新型コロナウイルス感染症の影響を受け、県内でサマーコンサートを開催している。また名古屋大学の入学式・卒業式など記念式典での演奏や依頼演奏など幅広く活動している。近隣の大学からも多くの学生が参加している。<br />
            </div>
        </div>
    )
}

export default About