import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import "./NewsCard.css"

const NewsCard = ({ src, title, auther, date, text, link }) => {

    const history = useHistory();

    return (
        <div className='news-card' style={{ cursor: "pointer" }} onClick={() => history.push(link)}>
            <div className="img" style={{ "background": `url(${src}) center no-repeat`, backgroundSize: "cover" }}></div>
            <div className="detail">
                <div className="title">{title}</div>
                <div className="auther">{auther} | {date}</div>
                <div className='text'>
                    {text}
                </div>
            </div>
        </div>
    )
}

export default NewsCard