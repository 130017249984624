import React from 'react'
import "./Access.css"

const Access = () => {
    return (
        <div className='access-section' id='access'>
            <div className='text'>
                <h2 className="title">ACCESS</h2>
                <div className="description">
                    <h2>刈谷市総合文化センター アイリス</h2>
                    <h3>電車でお越しの方</h3>
                    <p>JR東海道本線・名鉄三河線　刈谷駅南口よりウイングデッキ直結徒歩3分</p>
                    <h3>車でお越しの方</h3>
                    <p>
                        伊勢湾岸自動車道豊明ICより約20分・豊田南ICより約25分<br />
                        【刈谷市総合文化センター駐車場について】<br />
                        駐車場へのアクセス方法について<br />
                        ※総合文化センター駐車場は、バロー（みなくる刈谷）駐車場とは異なりますのでご注意下さい。<br />
                        ■利用時間<br />
                        入庫：8時30分から22時まで<br />
                        出庫：24時間可能<br />
                        ■収容車両<br />
                        610台<br />
                        ■制限<br />
                        車高：2.1メートル以下<br />
                        車長：5メートル以下<br />
                        ※バイク、自転車は入庫できません。<br />
                    </p>
                </div>
            </div>
            <div className='map'>
                {<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3268.6956079524475!2d137.004680015604!3d34.989288480361544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60049cee8ec8d24d%3A0xd0c627fa41d52077!2z5YiI6LC35biC57eP5ZCI5paH5YyW44K744Oz44K_44O8IOOCouOCpOODquOCuQ!5e0!3m2!1sja!2sjp!4v1656383407202!5m2!1sja!2sjp" width="500" height="400" style={{ "border": 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>}
            </div>
        </div>
    )
}

export default Access