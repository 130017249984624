import { Box, Button, Grid, TextField } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import image from "../../images/contact-image.jpg"
import "./Contact.css"
import emailjs from '@emailjs/browser';
import { useRef } from 'react'

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [isWritten, setIsWritten] = useState(false);

    const form = useRef();

    useEffect(() => {
        if (name != "" && email != "" && message != "")
            setIsWritten(true)
        else
            setIsWritten(false)
    }, [name, email, message]);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_8b7qsbm', 'template_xlr1uv9', form.current, "d-4b-8QOL44z6QpW1")
            .then((result) => {
                console.log(result.text);
                setName("")
                setEmail("")
                setMessage("")
                window.alert('お問い合わせを送信致しました。');
            }, (error) => {
                console.log(error.text);
                window.alert('お問い合わせの送信に失敗しました');
            });
    };

    return (
        <div className='contact-section' id='contact'>
            <div className="title">
                <h2>CONTACT US</h2>
                <img src={image} alt="" />
            </div>
            <Grid sx={{ ml: { md: "700px", sm: "auto" }, mr: { md: "100px", sm: "auto" } }}>
                <Box component="form" onSubmit={sendEmail} ref={form}>
                    <Grid item xs={12} sx={{ pt: 5 }}>
                        <TextField fullWidth label="氏名" variant="standard" name='name' onChange={e => { setName(e.target.value) }} value={name} />
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 5 }}>
                        <TextField fullWidth label="メールアドレス" variant="standard" name='email' onChange={e => { setEmail(e.target.value) }} value={email} />
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 5 }}>
                        <TextField fullWidth label="お問い合わせ内容" variant="standard" name='message' multiline rows={4} onChange={e => { setMessage(e.target.value) }} value={message} />
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 5 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!isWritten}
                        >
                            送信
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </div>
    )
}

export default Contact