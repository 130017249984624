import React, { useEffect, useState } from 'react'
import heroImage from "../../images/bg.jpg"
import "./Hero.css"

function leftTime(deadLine) {
    const day1 = new Date()
    const day2 = new Date(deadLine);
    const termDay = (day2 - day1)
    const date = Math.floor(termDay / 86400000)
    let hour = Math.floor(termDay % 86400000 / 1000 / 3600)
    if (hour < 10)
        hour = `0${hour}`
    let minutes = Math.floor(termDay % 86400000 / 1000 % 3600 / 60)
    if (minutes < 10)
        minutes = `0${minutes}`
    let seconds = Math.floor(termDay % 86400000 / 1000 % 3600 % 60)
    if (seconds < 10)
        seconds = `0${seconds}`
    if (termDay > 0)
        return (
            { date: [Math.floor(date / 100), Math.floor(date % 100 / 10), Math.floor(date % 10)], hour: [Math.floor(hour % 100 / 10), Math.floor(hour % 10)], minutes: [Math.floor(minutes % 100 / 10), Math.floor(minutes % 10)], seconds: [Math.floor(seconds % 100 / 10), Math.floor(seconds % 10)] }
        )
    else
        return (
            { date: [0, 0, 0], hour: [0, 0], minutes: [0, 0], seconds: [0, 0] }
        )
}

const Hero = () => {

    const [time, setTime] = useState({ date: [0, 0, 0], hour: [0, 0], minutes: [0, 0], seconds: [0, 0] })

    useEffect(() => {
        setInterval(() => { setTime(leftTime("2022/12/18 17:30")) }, 1000)
    }, [])

    return (
        <div className='hero-section'>
            <div className='hero-bg'>
                <div className='hero-img'>
                    <img src={heroImage} alt="第123回定期演奏会" />
                </div>
                <div className='hero-color'>
                </div>
            </div>
            <div className='hero-title'>
                <h1 className='nuso'>名古屋大学交響楽団</h1>
                <h1 className='t123'>第123回定期演奏会</h1>
            </div>
            <div className='hero-count'>
                <div className='date'>17:30 18 Dec 2022</div>
                <div className='count'>
                    <div className='item'>
                        <div className='time'>
                            <div>{time.date[0]}</div>
                            <div>{time.date[1]}</div>
                            <div>{time.date[2]}</div>
                        </div>
                        <div>
                            DAYS
                        </div>
                    </div>
                    <div className='colon'>:</div>
                    <div className='item'>
                        <div className='time'>
                            <div>{time.hour[0]}</div>
                            <div>{time.hour[1]}</div>
                        </div>
                        <div>
                            HOURS
                        </div>
                    </div>
                    <div className='colon'>:</div>
                    <div className='item'>
                        <div className="time">
                            <div>{time.minutes[0]}</div>
                            <div>{time.minutes[1]}</div>
                        </div>
                        <div>
                            MINUTES
                        </div>
                    </div>
                    <div className='colon'>:</div>
                    <div className='item'>
                        <div className="time">
                            <div>{time.seconds[0]}</div>
                            <div>{time.seconds[1]}</div>
                        </div>
                        <div>
                            SECONDS
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero