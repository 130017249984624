import { Grid, Typography } from '@mui/material'
import React from 'react'
import "./Supporter.css"
import Supporter01 from '../../images/Supporter01.svg'
import Supporter02 from '../../images/Supporter02.svg'
import Supporter03 from '../../images/Supporter03.svg'
import Supporter04 from '../../images/Supporter04.svg'

const Supporter = () => {
    return (
        <>
            <div className='supporter-container' id="supporter">
                <Typography variant='h5' component="h1" sx={{ fontFamily: "serif", mb: 3 }}>後援</Typography>
                <Grid container spacing={5}>
                    <Grid item xs={6} md={3}>
                        {<img src={Supporter01} alt="" />}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <img src={Supporter02} alt="" />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <img src={Supporter03} alt="" />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <img src={Supporter04} alt="" />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Supporter