import image01 from "../images/blog/2022_09_19.jpg"
import image02 from "../images/blog/2022_09_21.jpg"
import image03 from "../images/blog/2022_09_26.jpg"
import image04 from "../images/blog/2022_09_27.jpg"
import image05 from "../images/blog/2022_10_01.jpg"
import image06 from "../images/blog/2022_10_19.jpg"
import image07 from "../images/blog/2022_10_28.jpg"
import image08 from "../images/blog/2022_11_01.jpg"
import image09 from "../images/blog/2022_11_08.jpg"
import image10 from "../images/blog/2022_11_13.jpeg"
import image11 from "../images/blog/2022_11_13_.jpeg"
import image12 from "../images/blog/2022_11_15.jpg"
import image13 from "../images/blog/2022_11_21.jpg"
import image14 from "../images/blog/2022_11_28.jpg"
import image15 from "../images/blog/2022_12_05.jpg"
import image16 from "../images/blog/2022_12_10.jpg"
import image17 from "../images/blog/2022_12_13.jpg"
import image18 from "../images/blog/2022_12_16.jpg"
import image19 from "../images/blog/2022_12_18.jpg"


export const newsList =
    [
        {
            title: "コンサートマスター挨拶",
            author: "大野慶至",
            content: [
                "第123回名古屋大学交響楽団定期演奏会にてコンサートマスターを務めます大野慶至です。当団の活動への皆様の多大なるご支援、ご協力を賜りまして今回もこうして定期演奏会を開催することが叶いました。心より感謝申し上げます。",
                "今回の演奏会では、ベルリオーズ作曲の幻想交響曲をメインに据え、フランスゆかりの作曲家の名曲を3曲お届けいたします。",
                "さて、ベルリオーズの代表作である幻想交響曲を当団が演奏するのは実に28年ぶりのことであり、この選曲はここ数年の定期演奏会に比べても私たちなりの挑戦と銘打っております。練習に取り組む中で、名曲でありながらその難易度の高さに苦戦する場面も多くございました。",
                "技巧的でありながら抒情的な表現の求められる高弦。重々しく鬱々とした表現から打楽器的な表現に至るまで音楽表現の幅を求めらる低弦。随所でコンチェルトを思わせるソロが散りばめられた木管。後半に爆発的に登場し、華やかさや重厚さを兼ね備えたサウンドを求められる金管。そして、今までにない奏法、楽器を高度に扱いこなすことを必要とされる打楽器。",
                "やはり非常に難易度の高い曲であると痛感すると共に、団員一同これらを乗り越えられると信じ、選曲・練習に励んでまいりました。",
                "プロオーケストラには無い若々しさと高校生には出せない大人の表現、ある種モラトリアムを謳歌する大学生にある今だからこそ表現できる幻想交響曲をぜひお楽しみいただきたいと思います。",
                "それでは当日のご来場を団員一同心よりお待ちしております。"
            ],
            image: image19,
            link: "19",
            date: "2022/12/18"
        },
        {
            title: "名大オケに入団して",
            author: "小田のの子",
            content: [
                "・名大オケに入ったきっかけ",
                "私は元々ヴァイオリンをやっていて、地元のオーケストラに入っていました。だからなんとなくオーケストラ続けようかなーぐらいに思ってたのですが、練習の見学に行った時に先輩方の練習の様子をみて、こんなにも曲に熱意を持って演奏しているのか！と衝撃を受けました。今までなにかに真剣に取り組むことがなかった私ですが、ここでなら本気でなにかができるのでは無いかと感じて、名大オケに入ることを決めました。",
                "・普段の練習の様子",
                "練習する時はしっかり練習する、休む時はとことん休む、としっかりメリハリをつけて練習しています。学年の垣根を越えて仲が良いので、分からないところなどがあれば気兼ねなく質問ができ、先輩方もそれに精一杯答えてくれています！曲が難しくて練習したくない、、、、という時でも、みんな頑張って練習しているんだと思うと自然に、「練習したい！」、「もっと上手くなりたい！」と感じられるので、それが私の中で大きなモチベーションになっています。",
                "・本番への意気込み",
                "私たち1年生にとっては今回が初めての定期演奏会となります。さらに私はメインの曲である幻想交響曲を演奏するので、緊張しながらもとても楽しみです！本番まで残り数日ですが、それまで各自で出来ることを精一杯やり、最高の演奏会をみんなで作り上げましょう！！"
            ],
            image: image18,
            link: "18",
            date: "2022/12/16"
        },
        {
            title: "最後の合奏を終えて",
            author: "向江真太朗",
            content: [
                "12/10、11に最後の来団、tuttiを行いました。来団では、指揮者の先生、ハープの先生、コントラファゴットの先生をお迎えして練習を行いました。やはりプロの力は凄まじく、団員も負けじと自分たちの音楽を引き出せたことにより、演奏がよりよくなった実感を得ることができました。また、ラストtuttiではいよいよ団員だけでの全体練習は最後ということもあり、演奏に熱が入りました。最後にトレーナーやコンサートマスターが挨拶する場面では自然に拍手が生まれるなど温かい雰囲気で終わることができました。どちらの練習も残された時間をかみしめながら、チーム一丸となって練習を行うことができました。いよいよ本番が楽しみですね。",
                "また、今回のメイン曲となる幻想交響曲のトレーナーを担当しているチューバ3年向江真太朗から団員に向けて言葉をいただきました。",
                "¥¥",
                "来団、ラストtuttiと連日の練習お疲れさまです。",
                "早いもので、もう123rdもあと1週間ですね。",
                "みなさんは、この3ヶ月半どうでしたか？私は、今までにないくらい短く感じました。きっと、とても充実していたんだと思います。みなさんにとっても、充実した期間であったなら、嬉しいです。",
                "本番1週間前、ということで本番に向けた思いを少しだけお話ししようと思います。",
                "私は、この3ヶ月半、幻想交響曲という超大作にトレーナーとして、奏者として、真剣に向き合ってきました。どれだけやっても、詰めきれないもので、悩みに悩み、悩んで、悩みながら悩み続けてここまできました。幻想交響曲に乗ってくださった方には、細かく、時にはえげつないような要求もしてしまったと思います。",
                "ですが、こんな難曲を選んでも、日を重ねるごとに仕上がっていく様子を見ると、「今期はこの曲で間違いなかったな」と、いつも感じていました。",
                "あと1週間。この曲だけに限った話ではないですが、本番は良くも悪くもやってきたことしか披露することはできません。だから、”自分たち”の音楽を奏でましょう。”自分たち”の音楽を楽しみましょう。音楽に正解はないし、勝ち負けもありません。",
                "12月18日の本番が、この期間の集大成となるよう、残りの1週間、存分に音楽を楽しみましょう。"
            ],
            image: image17,
            link: "17",
            date: "2022/12/13"
        },
        {
            title: "名大オケに入団して",
            author: "篠原奨貴",
            content: [
                "今回はFg１年篠原奨貴が担当させていただきます。",
                "自分からは名大オケに入ったきっかけ、練習の様子について書かせていただきます。",
                "中高と吹奏楽部だった私は、大学でも音楽を続けたい気持ちがありつつ新歓の分奏見学に参加させていただきました。そこで聴いたブラームスの「大学祝典序曲」の分奏の質の高さに感激してこのサークルに入ることを決断しました。吹奏楽とは違い、オケの管楽器は一人ひとパート担当するという責任の重さがありつつ、ソリストとしての技量も求められるという、今までとはまったく違う環境に刺激を感じています。",
                "",
                "また、練習も学年の垣根を越えた、雰囲気での練習が進められています。常に山の上の練習場に行けば誰かが練習している、という点がサークル員の練習に対するモチベーションを向上させているようにも感じます。的確な指示を出してくださるトレーナー陣の方々に加え、運営に携わってくださるスタッフの方々など多くの人の協力の上に、毎週滞りなく練習できているこの環境には感謝しかないです。　",
                "",
                "定期演奏会まで10日を切り、仕上げの段階に入りつつある状況です。自分はファゴットを大学から始めたため、今回の幻想交響曲に乗ることになった時は不安しかありませんでしたが、今では、初めてのシンフォニーが幻想でよかったと心から思います。この曲が大好きになりました。今回の演奏会をもって卒団される先輩方も多くいますので、このメンバーで演奏することの喜びをかみしめながら、残りの練習に取り組んでいきたいです。頑張っていきましょう。"
            ],
            image: image16,
            link: "16",
            date: "2022/12/10"
        },
        {
            title: "11/26分奏来団、27来団を終えて",
            author: "西願寺彩音",
            content: [
                "11/26,27は4回目の来団Tuttiと前プロの来団分奏でした。連日の来団を終え、早くも本番まで2週間を切りました。練習を重ねるごとに緊張感も高まり、集中して練習に取り組む団員の姿が見られます。",
                "2ndトップの西願寺彩音ちゃんから、今回の来団を終え、残りの練習期間で意識すべきことについてメッセージを預かっています。 ",
                "¥¥",
                "11/26分奏来団、27来団お疲れ様でした！",
                "2日連続で篠崎先生の来団は大変だったと思いますが、学んだことをしっかり練習して次の来団までにはできるようにしておけると良いですね！",
                "",
                "さて本番まで3週間を切りました！",
                "9月から始まった123期間のゴールも見えてきたところで、残りの時間で出来ることってなんでしょうか？",
                "私は改めて楽譜と向き合うべきだと思います。強弱記号や音価は、しっかり守れていますか？",
                "気づかない間にリズムをはしょっていたり、弦楽器なら弾きやすいように弓の都合で強弱が守れていなかったりしませんか？",
                "また、周りの音を聞きながら演奏出来ていますか？楽譜は覚えてきたと思います。今回の曲は弦楽器と管打楽器の間での音の受け渡しが多く見られます。聞くことも大切ですが、自分の前に同じようなことをやっている人を見ることも大事です！どう演奏しているか耳と目で感じて、自分の演奏に取り込んで音楽を繋げていきたいですね。",
                "",
                "録音を聞き返すと、自分のやりたい理想の演奏にまだ届いていない箇所が見つかってくると思います。",
                "本番まで時間はまだまだあります。今出来ていない所を1つ1つ直して、本番には今よりももっともっと良い演奏をしたいですよね！！あと少し、みんなで頑張っていきましょう！！！",
                "",
                "今回は見応えのあるプログラムになっていると思いますので、お時間のある方は12月18日に刈谷まで足を運んで頂けると幸いです。心よりお待ちしております。"
            ],
            image: image15,
            link: "15",
            date: "2022/12/05"
        },
        {
            title: "11/28 山本直人先生管楽器レッスンを終えて",
            author: "後藤あい",
            content: [
                "本日は、オーボエ奏者の山本直人先生に管楽器セクションのレッスンをしていただきました。幻想交響曲は曲自体が長く、またとても難しい曲ですので、2時間強のレッスンでしたが、あっという間に時間が過ぎてしまいました。主に、強弱の幅と発音についてご指摘を受けました。私達の中では表現しているつもりでしたが、お客様に伝えるためには、思っているよりもずっと大胆に表現しなければならないということを改めて実感しました。",
                "さて、幻想交響曲という曲には、クラリネットのソロが随所に散りばめられています。ベルリオーズは憧れの人、ハリエット・スミッソンの姿をクラリネットの音に見ていたのだろうと個人的には思っています。このような曲でのトップはやりがいを感じると共に、身に余る大役だと感じてもいます。うまくいかないことばかりではありますが、お客様に良い演奏を届けられるよう、残り3週間、自分のできるところまで頑張ります。",
                "団員一同精一杯演奏しますので、お時間がありましたらぜひ、12月18日の演奏会に足を運んでいただけると幸いです。心よりお待ちしております。"
            ],
            image: image14,
            link: "14",
            date: "2022/11/28"
        },
        {
            title: "11/20 小林先生弦レッスンを終えて",
            author: "安藤俊介",
            content: [
                "名フィルのヴィオラの小林先生に弦レッスンをしていただきました。団員が持ち寄った気になる箇所を先生にご指導していただきました。かなり手応えを感じられるような場面も多くあり、大変有意義な時間となりました。",
                "本番までおおよそ一ヶ月ですが、今回のレッスンを糧に練習に励んでいきたいと思います。",
                "今回はチェロのトップの安藤俊介くんに弦レッスンを終えての感想と団員に向けたメッセージをいただいております。",
                "¥¥",
                "小林先生レッスンお疲れ様でした！！",
                "大きく2つ！まじめに復習しますね笑",
                "",
                "・旋律・リズムを司るもの→左手",
                "・音色・表現を司るもの→右手",
                "",
                "たしかに右手でリズムをつくるのは出来ますが、意外と左手でリズムを作るのって難しいんですよね…実は均等にポジション取りできてないことが多いんです、、、(管楽器であれば右手は口、左手は手に置き換えられるいったところでしょうか)",
                "みなさん、練習するときに右手と左手を同時にやろうとしていませんか？？今はリズムに集中する、音色に集中する、音程に集中するなどなど分けて考えてみては？？言われたことのみならずそこから発展させて活かせることは多いものです。",
                "",
                "・全員で休符を共有する",
                "出てる音を合わせるのはそりゃそうってなりますが、全員で休んでる部分こそ合わせるって大事なんですね…「無音の音を合わせる」といったところですかね。",
                "(「休符は“休み”じゃない」とはよくいったものですが、これ実感できた人も多かったのでは？！)",
                "",
                "👇",
                "大事なことは、新たに得た知識・テクニック・感覚を絶対に忘れないこと！！練習や来団多いなあ、、、って思うのもわかります笑けど、残り1ヶ月、そして本番よりいいモノにしたい！そのために1つでも多く吸収して本番を迎えられれば”音を並べる”だけじゃない”音を楽しむ”演奏会にできるはず！あと少しみんなで頑張ろう！！！",
                "",
                "以上Vcトップ安藤でした！"
            ],
            image: image13,
            link: "13",
            date: "2022/11/21"
        },
        {
            title: "来団Tutti３回とメイン曲の来団分奏が終わりました。",
            author: "井上真暢",
            content: [
                "来団Tutti３回とメイン曲の来団分奏が終わりました。",
                "123期間も中盤から終盤に差し掛かろうというところです。ここでこれを読んでいる団員の皆さんに問います。現状に満足していませんか？思い描いたものですか？音が並んで音楽は終わりではありません。追求できるところはたくさんあるはずです。今一度自分の演奏を客観的に見直してみるといいと思います。",
                "幻想交響曲の音源はたくさんありますが、どれも違った演奏です。(個人的に特にバーンスタイン指揮の幻想交響曲4楽章が好きです)自分の好きな音源を見つけるのも目標を作るのにいいかもしれません。残りの１か月で前プロ２曲も含めて「名大オケの演奏」を作っていきたいです。来団で篠﨑先生のアイデアを取り入れつつ自分たちの演奏を形にしていきましょう！",
                "最後まで気を抜かずに頑張りましょう！！",
            ],
            image: image12,
            link: "12",
            date: "2022/11/15"
        },
        {
            title: "幻想交響曲　作品14　楽曲解説",
            author: "水田弘賢",
            content: [
                "Hector Berlioz",
                "幻想交響曲　作品14",
                "Symphonie fantastique Op.14",
                "",
                "◎幻想交響曲という革新",
                "かつてのフランス紙幣に描かれるほど愛され、フランスを代表する作曲家として知られるエクトル・ベルリオーズ(Hector Berlioz，1803-1869)が幻想交響曲を作曲したのはベートーヴェンが亡くなってからわずか３年後の1830年のことであり、同作は当時の音楽シーンでは異例とも呼べる新しい試みに数多く挑戦した作品といえる。ふたつその挑戦を挙げるならば、標題音楽の確立と管弦楽法の拡大ということができるだろう。",
                "	まず標題音楽について、音楽そのものに物語やあらすじを付ける取り組みは、今日さほど珍しいことではないように感じるが、当時はベートーヴェンの交響曲第６番などにもみられたものの、そのような作品数は少ない。そこに一石を投じ、後世の標題音楽の可能性の幅を広げたという功績も彼が評価される理由の一つである。",
                "そして、管弦楽法に関して例えば使用楽器だけを見ても、オペラ劇場で用いられていたハープ、イングリッシュ・ホルン、鐘などがそれぞれ導入され、トロンボーンの3本編成やオフィクレイド(チューバで代用される場合もある)が2本、ファゴットが4本など、時代の近いベートーヴェンの交響曲と比較しても大きく異なっている。また、打楽器に目を向けても通常よりも多いティンパニやバスドラム等、大掛かりでゴージャスな編成を使用したことがわかる。奏法においてもやはり短いフレーズの中でのトリル奏法や装飾音の多用、弦楽器のコルレーニョ奏法(弓の棹を弦に当てて演奏する奏法)、ティンパニを4人で同時に叩かせる指示など、工夫が随所にみられる。また3楽章ではオーボエをステージ裏に配置しバンダとして演奏させることによって音楽を立体的かつ空間的にも楽しめるものにしている。これらによって作曲からおよそ200年という時間を感じさせず今聞いても非常にモダンな響きがする効果を生んでいるのである。",
                "◎ベルリオーズと文学",
                "	ベルリオーズは他の作曲家のような音楽の英才教育のもと育ったわけではない。医者であった父によってラテン語、文学、歴史、地理、数学などを学び、なかでも12歳から父親に暗唱するよう命じられていたラテン語の詩を読んだベルリオーズはその文学的な美に感銘を受けたという。彼自身の回想録においても、シェイクスピアとゲーテとの出会いが特筆すべき出来事として描写され、「文学」が生涯彼の芸術に大きな原動力を与えていたことは間違いないだろう。",
                "	そのような彼の文学的趣向が色濃く反映された音楽作品がまさに幻想交響曲である。副題には「ある芸術家の生活の挿話」(Episode de la vie d’un artiste)または「5部からなる幻想大交響曲」(Grande symphonie fantastique en cinq parties)という副題がつけられている。同曲はその全体に物語を与えられ、5楽章からなる標題に沿って進行する点で非常に特徴的である。物語を観客に確実に表現するために、当時ベルリオーズは自らそのあらすじを文章にして執筆し、演奏会時にはそれを配布することを義務付けるほどにこだわりを見せた。物語の発想を曲としてだけではなく文章のかたちで表現した点がまさに彼の音楽が文学との親和性に非常に優れていたことを裏付けている。しかしこれらの物語が彼の単なる創作ではなく、実際の経験に基づく自伝的内容を含んだものであることに注目した時、よりこの作品の独自性や斬新さを味わうことができるのである。",
                "	",
                "◎楽曲解説　",
                "	本プログラムノートでは、楽章ごとの解説と譜例に加えてベルリオーズ自身の手で執筆された標題(プログラム)をVn.市川亜紀とTb.丸岡大樹の訳で掲載する。",
                "≪第一楽章≫Reveries-Passions―夢、情熱 (導入部つきソナタ形式)",
                "	作曲家（ベルリオーズ）は、若い芸術家のことを想像する。とある有名な作家が “le vague des passions”（ぼんやりとした情熱）と呼ぶ精神病を患ったその芸術家は、夢の中で思い描いてきたすべての理想を満たすような魅力を持つ女性に出会い、ひどく恋に落ちる。奇妙な幻想のいたずらによってか、理想的なその女性の姿は、楽想と彼女のイメージが結びつくとき以外に彼の心中に現れることはない。彼はこの楽想に、恋に落ちた女性と同じ性質－熱烈な、洗練されたつつましい性質－を見いだす。",
                "この旋律のイメージとそのモデルは、二つのイデーフィクスのように彼を絶え間なく追い立てる。ゆえにallegroの冒頭部分で登場するメロディが交響曲の各楽章でしきりに繰り返されているのだ。幻想的で憂鬱な状態が、とりとめのない喜びの発作によって遮られ、怒りと嫉妬の衝動、愛情の回帰、涙、宗教的な慰めといった精神が錯乱するほどの感情へと移り変わっていく様子、これが第1楽章の主題となっている。",
                "*イデーフィクス　固定楽想とも訳される。幻想交響曲においては、芸術家が恋をする理想の女性をイデーフィクスという旋律に置き換えて曲中に頻繁に登場させるという手法をとっている。イデーフィクスこそ、ベルリオーズが熱狂的に愛した女性そのものを指す。フルートと第１ヴァイオリンによって軽快に演奏されるこのフレーズはまさに女性的で健康的な美しさやその音量のダイナミクスから若き日のベルリオーズの心情の色めき立つ様子を感じることができる。また、その裏で弦楽器によって演奏される8分音符の連続からも、恋の鼓動が高まっていく様子がうかがえる。",
                "≪第二楽章≫Un Bal―舞踏会 (ワルツ：古典派のスケルツォに相当)",
                "	芸術家は人生の中で最も多様な状況におかれている。舞踏会のどよめきのまっただ中にいたり、安らかな自然美の中で瞑想にふけっていたりする。しかし、町中、牧草地、どんなところにいたとしても、最愛の彼女は彼の前に現れて彼の心をかき乱すのである。",
                "2楽章に登場するイデーフィクスは実際の女性そのものというよりは芸術家が回想する女性の姿である。頭の中で女性を思い出してしまう切ない心情は譜例最後の半音階からも読み取ることができる。楽章全体としては華やかなワルツであり、芸術家が舞踏会に参加する様子であるが、そこに想い人の姿はない。慌しくにぎわう舞踏会の中で最後は一気にテンポを上げ突然の終わりを迎える。",
                "≪第三楽章≫Scéne aus Champs―野の風景　(２部形式によるアダージョ)",
                "	田園地帯のある晩、彼は遠くの方から2人の羊飼いが「ランツ・デ・ヴァッシュ」(スイスの伝統的な牛追いの唄)を歌うのを聞く。その牧歌的な二重唱や、周囲の情景、風によって揺れ動く木々のささやかなざわめき、彼が近頃抱いた希望の源となるもの、そのすべてが相まって彼は今までに感じたことのない安堵を覚える。そしてそれは彼のアイデアに、より快活で想像力に富んだ感覚をもたらしてくれる。ここで、彼は自身の孤独について考える。もはやすぐにでも独りになる事を望んでいるのだ。もし彼女が裏切りでもしたらどうしようか…！希望と恐怖の交錯と、不吉な予感に蝕まれた幸福感からadagioの主題が完成した。最後に、羊飼いの1人が再び「ランツ・デ・ヴァッシュ」を歌い始める。もう一方の羊飼いは反応を示さない…。遠雷…孤独…静寂…。",
                "	場面が変わり牧場での牧歌的なメロディが印象的な第3楽章にあっても、やはり女性の姿は頭から離れず、基本形である第１楽章のイデーフィクスから比べると付点や３２分音符などが用いられ厳しいリズムで表されることによって、揺れ動く心情がさらに再現されている。静寂の中に安堵と不安が交錯する不安定な中、ついにはイングリッシュ・ホルンの孤独な羊飼いの唄と4人のティンパニストによる遠雷をきく。",
                "≪第四楽章≫Marche au Supplice―断頭台への行進　(行進曲の挿入)",
                "	芸術家はいまや彼の一途な愛が報われないことを確信し、アヘンを飲んで自殺を図ろうとする。だが、彼が服用したアヘンの量は死に至る程のものではなかった。彼はこの世で最も恐ろしい幻想に誘われて眠りに落ちる。彼は夢の中で愛する女性を手にかけてしまい、死刑を判決される。そして断頭台へと連れて行かれ、死刑が執行される様子を自分の目で見届けるのである。行列の行進は、時に荒々しく、時に厳粛で、威厳と華々しさを備えた行進曲に合わせて歩みを進めている。轟音の後に、ドスンドスンという重い足取りが荒々しく続く。行進曲の最後にはイデーフィクスの最初の4小節が流れるのだが、それは、断頭台の斧が首を落とす瞬間に、最愛の人を思い出す最期の様子を示すようである。",
                "さらに場面が変わり、トランペットやトロンボーン、オフィクレイドなどの金管楽器に行進曲のファンファーレが与えられることで、断頭台での処刑に向かう緊張感が一気に高まる。弦５部のユニゾンによって演奏されるスタッカートのリズムにも同様に処刑台へと歩を進める鼓動の加速が感じられる。そしてついに処刑台までたどり着いたときイデーフィクスによってふたたび自身が殺めた女性の回想が現れる。いわば走馬灯のようなそのわずか4小節の直後、4分音符のフォルティシモでギロチンの刃が落ちる。弦による３拍のピツィカートは切り落された首が鈍い音で転がることを表現している。",
                "≪第五楽章≫Songe d’une nuit du Sabbat―サバトの夜の夢　(導入つきアレグロ)",
                "	彼は自分が魔女のサバト＊にいることを悟る。彼の葬儀のために集まったぞっとする見た目の亡霊たちや魔女、あらゆる種の怪物に囲まれているのだ。奇妙な音やうめき声、はちきれんばかりの笑い声、遠くで誰かが会話しているような叫び声が聞こえる。愛するあのメロディが再び聞こえてくるのだが、そのメロディはかつての洗練されたつつましい性質を失っている。それはただのありふれた、奇妙なダンスのメロディに過ぎない。そしてなんと、サバトにやってきたのは紛れもなくあの女性である。彼女は歓喜の咆哮で歓迎される…。彼女は悪魔たちのどんちゃん騒ぎに参加する…。弔いの鐘が、「怒りの日」2の滑稽なパロディかのように鳴り響き、サバトの踊りが始まる。サバトの踊りと「怒りの日」が一緒になって。",
                "	*魔女のサバト ヨーロッパで信じられていた魔女あるいは悪魔崇拝の集会。魔宴、魔女の夜宴・夜会ともいう。",
                "	*怒りの日 カトリック教会の葬儀で歌われる讃美歌、レクイエム。",
                "この楽章は終始、奇怪でおどろおどろしいという形容が最もふさわしいだろう。導入からチェロとコントラバスによる怪しい音階ののち、高音楽器の魔物たちの囁きを聞く。それらの不協和とホルンのグリッサンドが登場すると、クラリネットと打楽器から始まるアレグロに突入する。目まぐるしく情景の変わる死後の世界にあってついに終楽章のイデーフィクスとして女性が登場するとき、その姿はまさに異形として表される。エスクラリネットは変わり果てた女性の姿を表しており、装飾音やトリル奏法の多用によって不気味さを演出すると同時にピッコロ、フルートがそれに加わることでオーケストラの中で最も高い音域でけたたましく聞こえさせる効果がある。そして聞こえてくるのは、彼岸の鐘の音と怒りの日。オフィクレイド２本とバスーン４本という重低音の賛歌はカトリック教会の美しさとは完全に方向を異にする異世界の歌である。最終的には、サバトの踊り―魔女たちのロンドがまたしても低弦をきっかけに始まり狂宴のトゥッティの中、金管楽器のコラールとの合奏を迎え、トロンボーンをはじめとした管楽器の半音階によって劇的に締めくくられる。",
                "",
                "◎演奏にあたって",
                "通常我々の定期演奏会での曲目は約半年にわたる長期間の話し合いによって決定されますが、今回この曲を選ぶにあたって多くの可能性やメリットを感じる一方で、難しさに対するリスクや、特殊で大掛かりな編成による準備の問題、またこうした難解な作品背景を理解しながら表現しなければならない不安など、多くの苦労を経験しました。しかし、我々のたった一度の第123回定期演奏会に向け、それを特別で記憶に残るものにしたいという強い意思のもと決定に至りました。“名大オケ”として「幻想交響曲」を選曲したのは1994年の第66回定期演奏会以来、実に28年ぶりであり、これまでの先輩方の伝統を感じるとともに長きにわたって温められてきたこの大曲を“我々の特別で新しい挑戦”として練習できたことを改めて嬉しく感じています。本日は、同様にこの曲を通して音楽史に“新しい挑戦”をしたベルリオーズにならい、その偉業に敬意を表すのはもちろんのこと、我々の演奏を聴きにお越しくださった皆様に心より感謝しながら、大学生らしい若さや大学オケとしての熱量のこもった“幻想”を大切に演奏いたします。",
                "	この第123回定期演奏会が皆様の何か心に残るものとなれば大変光栄に思います。",
                "◎参考文献",
                "・三井麻衣，(2014)，『登場人物が存在する交響曲：ベルリオーズ《幻想交響曲》におけるイデー・フィクス』，上智大学文化考証学研究",
                "・三井麻衣，(2017)，『<研究ノート>エクトル・ベルリオーズ《幻想交響曲》における物語性』，上智ヨーロッパ研究，9号161-167",
                "・作曲家ベルリオーズによる曲の解説(訳 丸岡大樹・市川亜紀)",
            ],
            image: image11,
            link: "11",
            date: "2022/11/13"
        },
        {
            title: "交響詩「レ・プレリュード」　楽曲解説",
            author: "萩友希代",
            content: [
                "Franz Liszt",
                "交響詩「レ・プレリュード」",
                "Les Préludes",
                "◎リストについて",
                "フランツ・リストはハンガリー出身でヨーロッパにて活躍した作曲家であり、ピアニストでもあった。",
                "・生い立ち",
                "1811年にハンガリー王国で生まれたリストは、ピアニストだった父に幼少期から音楽の手ほどきを受け、幼少期から演奏活動をしていた。1822年にウィーンで作曲をサリエリに、ピアノをチェルニーに師事し、翌年にはパリに移住してパリ音楽院への入学を希望するも、外国人であることを理由に拒否されている。幼少期からヨーロッパ各地を演奏旅行しており、この様々な国での経験は彼の音楽に色濃く反映されている。",
                "・ピアニストとしてのリスト",
                "1832年、リストはヴァイオリニストであったパガニーニの演奏を聴いて感銘を受け、ピアノのパガニーニになることを決意した。パガニーニの超絶技巧をピアノでも実現しようと猛練習をしたのである。やがて「ピアノの魔術師」と呼ばれるようになったリストは、1839年から8年間、当時最高のピアニストとしてヨーロッパ各地を周り、その際には自身でピアノへの編曲を行った交響曲やオペラも演奏している。",
                "・作曲家としてのリスト",
                "1847年にはピアニストとしての活動を引退し、作曲活動に専念するようになる。自身がピアニストでもあったため、ピアノ曲が作曲の中心であるが、他に交響曲、ピアノ協奏曲、室内楽曲、教会音楽なども作曲している。本作品『前奏曲』を作曲した頃が彼の作曲家としての全盛期であり、彼の書いた交響詩13曲のうち12曲はこの頃に作曲されたものである。彼の大きな功績の一つが、この「交響詩」というジャンルの確立である。",
                "・リストと交響詩",
                "交響詩とは、一般に連続する単一楽章からなる、詩や小説・絵画・風景などをもとにした標題音楽である。同時期に活躍していたベルリオーズはすでに、本演奏会でも取り上げる『幻想交響曲』(1830)など標題音楽をいくつも作曲していたが、このような形式の曲を「交響詩」と呼んだのはリストが初めてである。前述のベルリオーズ『幻想交響曲』の固定楽想(イデー・フィクス)や、ベートーヴェンの演奏会用序曲『コリオラン』『エグモント』等の作品や、ワーグナーの文学や演劇と音楽を一体化させるという芸術観に影響を受けていた。リストは13曲の交響詩を作曲しており、中でも本作品『前奏曲』は交響詩の始まりともいえる作品であり、また代表作であるともいえる。後に彼の交響詩は、スメタナ、フランク、サン＝サーンス、チャイコフスキーなど多くの作曲家に影響を与えることとなる。",
                "◎楽曲について",
                "交響詩『前奏曲』は、1848年に作曲され、1853年から1854年にかけて改訂された。1854年に自身の指揮・ワイマール室内管弦楽団の演奏で初演され、今もなお世界中のオーケストラで演奏されている、彼の交響詩の中で最もよく知られた作品である。",
                "・作曲の経緯 ―ラマルティーヌと『前奏曲』",
                "本作品は最初から交響詩として作曲されたものではなく、もとは合唱曲『四大元素』の序曲として、『四大元素』のモチーフなどを用いて作曲された。その後、1853年からの改訂の際に、アルフォンス・ド・ラマルティーヌが書いた『新瞑想詩集』に収録された詩「レ・プレリュード」をもとにした序文を標題として付け加え、現在の形の『前奏曲』ができあがったのである。交響詩でありながらも、詩からの暗示で作曲されたものではなく、先に楽曲が作曲されて、標題は後から付け加えられたものである。",
                "「人生とは、その厳粛な第一音を死が奏でる、未知の讃美歌への一連の前奏曲であるほかに何があるだろうか。」こう始まるリストの序文の端々と、緩―急―緩―急の四部からなる曲の構成とがリンクしているのである。",
                "・第一部 ―はじまり、愛",
                "ピチカートに続き、弦楽器が主題を提示する。この主題の冒頭C-B-Eは『四大元素』の「星々」からとったもので、全曲を通して用いられる極めて重要なものである。主題が繰り返され主部に入ると、冒頭主題の変形を低音楽器が力強く奏でる。第1ヴァイオリンによるアルペジオや低音楽器に支えられながら、第2ヴァイオリン・チェロ・ホルンが第1主題を提示する。第1主題は『四大元素』の「大地」の旋律である。ホルンによって、またもや冒頭主題に基づくものである、「愛の主題」であるともいえる第２主題が提示され、木管楽器へと移り、やがてフルートによる第１主題の回想で第一部は幕を閉じる。",
                "・第二部　―嵐",
                "チェロによる冒頭主題の変形で嵐が予告される。弦楽器・木管楽器が加わって緊張感が高まると、やがてトロンボーンが冒頭主題で嵐の始まりを告げる。嵐の頂点ではトランペット・ホルンのファンファーレのような音形で激しさを表現し、そのリズムがティンパニや第1ヴァイオリンへと引き継がれながら嵐がだんだんと静まっていく。",
                "・第三部　―田園",
                "オーボエとヴァイオリンが第１主題を再現し、嵐が去った後の静けさと平穏を表現する。ホルンからオーボエ、クラリネットへと田園風景を連想させる素朴な旋律が受け渡され、やがてヴァイオリンが「愛の主題」を歌う。主題を奏でる楽器が次々と移り変わりながら音楽はだんだんと広がりを見せると、トランペットのファンファーレが皮切りとなり音楽は前進していく。",
                "・第四部　―戦い",
                "第１主題をトランペットとホルンが堂々と歌い、幕を開ける。標題にもある「トランペットの警笛」と木管楽器が音楽を高め、やがてすべての楽器が加わって「愛の主題」に基づく荘厳な行進曲となる。第一部の主部が打楽器も加わって再現され、勇壮なまま曲は幕を閉じる。",
                "◎参考文献",
                "柴辻純子『リスト 交響詩《レ・プレリュード》』全音楽譜出版社 (2020)",
                "原田玲子『ヨーロッパ芸術文化と音楽』音楽之友社 (1986)",
                "Fran Liszt 『Les Préludes』Breitkopf & Härtel",
            ],
            image: image10,
            link: "10",
            date: "2022/11/13"
        },
        {
            title: "歌劇「サムソンとデリラ」よりバッカナール　楽曲解説",
            author: "新海佐和",
            content: [
                "本日は、当演奏会のオープニング曲について解説します。執筆はVaトップの新海佐和です。楽曲解説は当日プログラムにも掲載いたします。",

                "サン＝サーンス作曲",
                "歌劇「サムソンとデリラ」よりバッカナール",

                "◎作曲者について",
                "カミーユ・サン=サーンス（Camille Saint-Saëns 1835-1921）はフランスで活躍した作曲家、ピアニスト、オルガニスト、指揮者である。『序奏とロンド・カプリチオーソ』、『死の舞踏』、『動物の謝肉祭』などで知られている。当団では、昨年5月の第120回定期演奏会において「交響曲第3番 ハ短調 作品78 『オルガン付き』」を演奏した。",

                "◎歌劇『サムソンとデリラ』について",
                "サン=サーンスが1869年から翌年にかけて制作した全3幕の歌劇。旧約聖書の士師記の13章から16章に記載されている士師サムソンの物語に基づいて、サン=サーンスの従兄弟であるフェルディナン・ルメール（Ferdinand Lemaire 1832-1879）が台本を作成した。1875年に第1幕のみが初演された。1877年、このオペラに興味を持っていたフランツ・リスト（Franz Liszt 1811-1886）の働きかけによりワイマール宮廷劇場で初演された。サン=サーンスが生涯に作った13のオペラのうち最も広く知られている作品である。",
                "舞台は紀元前12世紀、ヘブライ人（旧約聖書において救われる種族）とペリシテ人が対立している。ヘブライ人側に怪力のサムソンが現れることで、ヘブライ人たちが優勢となる。ペリシテ人たちは、サムソンの力を削ぐために彼の元に美女のデリラを送り込み、サムソンの怪力の源を聞き出そうとする。最初は正直なことを言わないサムソンだったが、デリラの魅力に負け、自分の怪力の源が生まれてから一度も切ったことのない髪の毛にあることを漏らしてしまう。デリラは眠っているサムソンの髪を切り、ペリシテ人たちは彼の目を抉って無力化し、地下牢に幽閉する。サムソンは地下牢で彼の同胞たちからの非難の幻聴を聞き、同胞を裏切ってしまったことを悔いる。彼が力を取り戻そうと神に祈ると奇跡的にその力が戻る。彼は神殿を崩壊させ、3000人のペリシテの群衆と共に自らも死んでいく。このシーンでオペラは幕を閉じる。",
                "オペラの多くは男女の恋愛を題材にしたものだが、この作品はそうではない。一般的なオペラの最中に出てくるアリアでは愛を歌うことが多いが、デリラが歌うアリアは真実の愛を謳うものではなく、どこか偽りのものであり、サムソンを騙して力を剥ぎ取ろうとする意思が根底にはある。この点でこのオペラは一般的な作品とは異なっている。",

                "◎バッカナールについて",
                "バッカナールは第3幕第2場に演奏されるバレエの音楽である。動画サイトに幾つかバレエの動画がアップされているのでぜひご視聴いただきたい。バッカナールとは酒の神バッカスを祀る宴の席の音楽で、ペリシテ人がサムソンを捕らえて地下牢に閉じ込めた後のペリシテ人による宴の場面を表現している。この曲が酒の神バッカスを祀る宴席の曲だと知った状態でバレエを見てみて「人生で一回はこんな雰囲気の飲み会に行ってみたい」と感じた。日本では味わえないオリエンタルな雰囲気。不思議で、少しいかがわしい。装飾をたくさんつけた女性たちが踊る煌びやかで非日常的な空間。なんとも異国的。サン = サーンスが生きた当時のフランスでは、中東に対するイメージはこのようなものだったのだろうか…。（余談だが19世紀のフランスオペラ界では「後半にバレエを挿入する」ことが常識であったらしい。ブルジョワ男性たちは踊り子たちが舞台上で軽やかに舞うのを見るために後半の幕から入場するということもあったのだという。確かに、こんなに楽しそうなバレエならバレエを見るためだけにわざわざ会場に足を運んでしまう気持ちもわかるような…）",

                "◎楽曲解説",
                "全体の印象は、先ほども述べた通りエキゾチックで少し官能的、そして華やかというところだろうか。この印象は、曲中あちこちに散りばめられアラビアンな響きによって醸成されている。このアラビアンな響きを作り出すためにサン = サーンスは3つの工夫をした。1つ目は独特なスケールを使用したことである。全体を通して短調の音階が用いられていることが多いが、自然短音階や和声的短音階の他にフリギア旋法と呼ばれる旋法やそれをさらに変化させた旋法を用いている。和声的短音階やこのような音階のなかには増2度（半音×3）という音程が含まれており、普段聞き馴染みのないこの音程が異国らしさ / 異教らしさを感じさせている。2つ目は、中東で用いられる楽器をイメージしながら作曲をしたことである。例えば、オーボエの音色は楽器の音を模したものとして用いられている。この他にも弦楽器のピチカートやカスタネットが大活躍する部分に注目していただきたい。3つ目は、裏拍をこれでもかと強調したことである。西洋音楽では表拍が重視されるのが普通だが、この曲では至る所で裏拍が強調される。練習を始めたばかりの頃は裏拍から始まるメロディーや伴奏に苦労した。",
                "この曲はオーボエのソロで始まる（ここはサムソンを捉えることのできた喜びを神に伝えるシーン？？）。その後木管楽器による躍動的な旋律が始まり、バイオリンに引き継がれる。この旋律は2拍子の2拍目から始まっている。弦楽器のユニゾンが始まり、華やかな盛り上がりを見せる。その後哀愁漂うエキゾチックな旋律が登場する。この部分にもフリギア旋法のスケールが用いられている。この旋律の裏では弦楽器が完全五度のハーモニーを奏でている。どうやらこの完全5度の響きというのは原始的な雰囲気を感じさせるとか。躍動的な旋律が再度登場し、その後弦楽器によるゆったりとした旋律が始まる(これは神へ感謝の合唱？？）。急に現実に引き戻されたかのように、華やかで躍動感あふれる旋律が再登場し、曲が盛り上がる。その後盛り上がりは最高潮に達し、勢いよく曲が終わる。",


                "◎参考文献",
                "『最新名曲解説全集 第19巻 歌劇 Ⅱ』音楽之友社（1980）",
                "『クラシック名曲ガイド⑦ 歌劇』音楽之友社（1995）",
                "解説者：溝部国光『Camille Saint Saëns 〈サムソンとデリラ〉バッカナール』（スコア）日本楽譜出版社（1992）"
            ],
            image: image09,
            link: "9",
            date: "2022/11/08"
        },
        {
            title: "3年ぶりの合宿を行いました",
            author: "萩友希代",
            content: [
                "10月7日(金)〜10月10日(月祝)の3泊4日で、全体合宿を行いました。",
                "2019年、117thの練習での合宿以来なので、なんと3年ぶり！当時1年生だった団員が4年生となり、3年生以下にとっては初めての合宿でした。",
                "短期間に集中して練習をしたことで、演奏の質がグッと高まりました！",
                "普段はあまり話さない、他の学年や違う楽器の人とも食事や部屋でなど関わる機会がたくさんあり、交流を深められたのもよかったです。食事の際は持ち回りでいただきますのあいさつをしましたが、各々個性が出ていましたね(笑)",
                "2日目の夜に行われたアンサンブル大会では5つのチームがみんなの前で演奏しました。普段の練習ではなかなかアンサンブルもできないので、いい機会になりましたね。",
                "3日目はスタッフの4人がレクを企画してくれました！実はレクをやると決まったのは合宿3日目の昼くらい。敏腕スタッフが持ち前の企画力で短時間で仕上げてくれました。ペーパータワーが特に大盛り上がりでしたね！",
                "たくさん練習をして、みんなと仲良くなって、とても充実した4日間でした。スタッフの4人はじめ運営に関わってくれたみなさん、本当にありがとうございました！",
                "123rd期間も折り返しを過ぎましたが、引き続き練習頑張りましょう！",
            ],
            image: image08,
            link: "8",
            date: "2022/10/31"
        },
        {
            title: "10/22 は２回目の来団でした。",
            author: "田中千湖",
            content: [
                "今回から運営以外の団員にも記事を書いていただきます！第一回を担当しましたのは幻想交響曲Flトップの田中千湖です。",
                "ー－－－",
                "10/22 は２回目の来団でした。",
                "合宿やたくさんの練習を経て、前回の来団よりも篠崎先生の引き出しを多く出せたかな？と感じてました。",
                "チューニングに関してもアドバイスを頂いたので、今後みんなで意識していけると良いですね！",
                "次回来団は本番の会場である刈谷なので、充実した時間になるよう、全力で練習頑張りましょう！"
            ],
            image: image07,
            link: "7",
            date: "2022/10/28"
        },
        {
            title: "弦レッスンを行いました。本番まであと二か月！",
            author: "福地咲樹",
            content: [
                "10/16にVa.小林伊津子先生に来団いただき弦レッスンを行いました。",
                "小林先生は、今の音楽をより良いものにするためにボウイングや弓の使い方・イメージの仕方等を端的に教えてくださるので、レッスン前と後で音楽がぐっと良くなるのを強く感じることができます。また先生が実際に演奏してくださるのも大変参考になり、プロの方の生の音を聞くことができる貴重な機会にもなりました。",
                "バッカナール・レプレリュード・幻想交響曲の3曲すべてにおいて大きな学びを得ることができました。",
                "来週は2回目の指揮者来団です。レッスンで学んだことを踏まえ分奏・パート練習でしっかりと準備をして行けたらと思います。"
            ],
            image: image06,
            link: "6",
            date: "2022/10/19"
        },
        {
            title: "10/1(日)は初来団@犬山市でした！",
            author: "市川亜紀",
            content: [
                "本日は篠﨑先生をお迎えしての初来団日でした！",
                "先生からたくさんアドバイスをいただき、団員一同、真剣に練習に取り組むことができました。課題もたくさん見つかり、非常に貴重な練習の機会となりました。",
                "次の来団日は3週間後の10 / 22（土）です。3週間の間には合宿も挟みます。この練習期間で今回の課題をクリアし、先生がおっしゃっていた表現力を磨き上げて次回来団に臨んでいきます🔥！"
            ],
            image: image05,
            link: "5",
            date: "2022/10/01"
        },
        {
            title: "弦合宿を行いました！",
            author: "福地咲樹、市川亜紀",
            content: [
                "こんにちは！名古屋大学交響楽団第123回定期演奏会スタッフです。",
                "9/10~9/12に、弦楽器のメンバーで2泊3日の弦合宿を行いました。今回は練習期間開始から間もないため、前半は個人・ペアでの譜読みや、パート練習を中心に行い、3日目に全曲の分奏を行いました。普段とは違い、練習に使える部屋数が多く、自分の音に集中しながら譜読みを進めることができました。123のプログラムはフランス固めのプログラムで、譜読みに苦戦する団員も多数。しかし、丁寧に譜読みができるよう、弦トップ陣が個人・ペア練習の時間が多めに取られたスケジュールを組んでくれたり、上級生が下級生を指導しながら一緒に譜読みをしたりする様子も見受けられ、練習内容に工夫が感じられました。",
                "また旅館の近くには海があり、夕方に自由時間を設けて海に散歩に出かけてリフレッシュしたり、1日の練習後に花火大会を行って親睦を深めたりもしました。団員にとってもより思い出深い合宿になったのではないでしょうか。",
                "コロナウイルス感染拡大の影響で、当団が合宿を行うのはなんと3年ぶり。参加者の8割が合宿初参加で、私たち運営側ももれなく初合宿だったため、準備段階から不安だらけでしたが、合宿係の子やほかの団員のサポートもあって無事合宿を終えることができました。感染対策に関しても、食事の回数を分けたり、合宿前日に抗原検査を実施したりして万全の対策を行いました。合宿から2週間が経過しましたが、参加したすべての団員の体調は良好で、引き続き演奏会に向けての練習に取り組んでおります。",
                "10月には全体合宿も行う予定です。日々の練習を大切にしながら、コロナ感染対策も徹底しつつ活動していこうと思います。応援よろしくお願いいたします。"
            ],
            image: image04,
            link: "4",
            date: "2022/09/27"
        },
        {
            title: "9/19(月)は初tutti@東海市でした！",
            author: "市川亜紀",
            content: [
                "初めて全曲の合わせを行い、曲の雰囲気もかなりつかめてきたのではないでしょうか。",
                "前日から台風の影響が予想されていたため、予定より撤収時間を早めての練習だったのですが、練習途中で暴風警報が出てしまい、急遽練習中止となってしまいました。無事団員全員が帰宅することができ、事なきを得ました。コロナ禍に台風と苦難もありますが、だからこそ一回の練習の大切さを感じることができますね。",
                "10/1には初来団を控えています。今回のtuttiで見つけた課題をクリアして先生と初の合わせが行えるように頑張っていきましょう😊"
            ],
            image: image03,
            link: "3",
            date: "2022/09/26"
        },
        {
            title: "ビラが完成しました！",
            author: "福地咲樹",
            content: [
                "ビラデザインを担当したのはインスペクター野崎と庶務福地です。",
                "メイン曲である幻想交響曲から想起するイメージを二人で出し合い、「黒バックにカラフルなデザイン」「華やか」「今までにない雰囲気」などに合致する素材画像を探しました。",
                "しかし思ったものがなく、いっそ素材を作ってしまえ！ということで、福地が趣味のカメラで撮影した花の写真に、ビビッドでカラフルな色を重ねて、今回のビラの素材写真が出来上がりました。",
                "幻想交響曲のイメージにぴったりな、今までにないような現代的なデザインのものを作成することができ、大変満足しています。"
            ],
            image: image02,
            link: "2",
            date: "2022/09/20"
        },
        {
            title: "123回定期演奏会に向けての練習が始まりました！",
            author: "市川亜紀, 福地咲樹",
            content: [
                "9/3から練習開始、9/5は初分奏でした。初回練習からたくさんの団員が参加してくれています😊",
                "123回定期演奏会の曲目は今年の1月から技術委員会にて選曲を開始し、4月末にメイン曲の『幻想交響曲』、8月中旬に前プロの『レ・プレリュード』、『サムソンとデリラよりバッカナール』が決定しました。",
                "各パートの代表である技術委員の方々が幾度も話し合いを重ねてくださって決定した3曲。なかなかに重いプログラムではありますが、約3ヶ月の練習期間を通して曲を完成させていきたいところです！",
                "依然として新型コロナウイルスの影響は消えませんが、十分な感染対策を行った上で練習を進めていきます。"
            ],
            image: image01,
            link: "1",
            date: "2022/09/19"
        }
    ]