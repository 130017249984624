import { Container, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { newsList } from '../newsList';
import MediaQuery from 'react-responsive';

const NewsPage = () => {
    let { id } = useParams();
    const [data, setData] = useState("");

    useEffect(() => {
        setData(newsList.find(e => e.link === id));
    }, []);

    return (
        <div style={{ paddingTop: "10rem", paddingBottom: "10rem", backgroundColor: "#F3F2EE", textAlign: "center" }} >
            <MediaQuery minWidth={820}>
                <Container>
                    {data !== "" &&
                        <div style={{ backgroundColor: "#ffffff", padding: "5rem", fontFamily: "serif" }}>
                            <p style={{ color: "#A38037" }}>{data.date}</p>
                            <h1>{data.title}</h1>
                            <img src={data.image} alt="" style={{ width: "100%", aspectRatio: "16/9", objectFit: "cover" }} />
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margin: "2rem 0" }}>
                                {data.content.map((text, index) => {
                                    if (text !== "¥¥")
                                        return (
                                            <p style={{ width: "80%", textAlign: "left" }} key={index}>{text}</p>)
                                    else
                                        return (<Divider sx={{ width: "100%", my: 2 }} />)
                                })}
                            </div>
                            <p style={{ textAlign: "right" }}>{data.author}</p>
                        </div>}
                </Container >
            </MediaQuery>
            <MediaQuery maxWidth={819}>
                <Container>
                    {data !== "" &&
                        <div style={{ backgroundColor: "#ffffff", padding: "1rem", fontFamily: "serif" }}>
                            <p style={{ color: "#A38037" }}>{data.date}</p>
                            <h1 style={{ fontSize: "1.2rem" }}>{data.title}</h1>
                            <img src={data.image} alt="" style={{ width: "100%", aspectRatio: "16/9", objectFit: "cover" }} />
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margin: "2rem 0" }}>
                                {data.content.map((text, index) => {
                                    if (text !== "¥¥")
                                        return (
                                            <p style={{ width: "80%", textAlign: "left" }} key={index}>{text}</p>)
                                    else
                                        return (<Divider sx={{ width: "100%", my: 2 }} />)
                                })}
                            </div>
                            <p style={{ textAlign: "right" }}>{data.author}</p>
                        </div>}
                </Container >
            </MediaQuery>
        </div >

    )
}

export default NewsPage