import { Container, Divider } from '@mui/material';
import React, { useEffect } from 'react'
import "./HandsUp.css"

const HandsUp = ({ setIsHome }) => {

    useEffect(() => {
        setIsHome(false)
    }, []);

    return (
        <div className='hands-up-container'>
            <Container>
                <div className="hands-up-title">
                    <h2>
                        第123回定期演奏会にご来場されるお客様へ
                    </h2>
                </div>
                <div className='hands-up-content'>
                    <ol>
                        <li>
                            当日券(900円)の販売を予定しております。予告なく終了する場合がございますのでご了承ください。<br />
                            預かりチケット・チケットぴあの引き換えは、入り口にて専用ブースを設けますのでブースにてお受け取りください。<br />
                            当日券販売開始、預かりチケット・チケットぴあの引換開始は16時です。
                        </li>
                        <Divider/>
                        <li>終演後の楽屋への直接訪問・舞台・ホワイエでの直接の接触・出待ち等はお控えください。<br />
                            出演者にクローク(差し入れ)がある場合、今回の演奏会ではクロークを受け付けますので、ロビーにてお預けくださいませ。<br />
                            尚、クロークを預ける場合<b>【①送り先団員のパート ②名前 ③送り元のお名前】</b>を記入していただきます。予め記入して持参いただくか、当団で用意するシールにご記入ください。また、クロークは演奏会終演後には受け付けいたしません。
                        </li>
                        <Divider />
                        <li>感染対策のため、お客様情報の収集にご協力をお願いします。チケットの所定の欄にお客様情報をご記入いただき、入場の際に受付員にお渡しください。さらに、座席とお客様情報を紐づけるため、チケット中段をお座りになる座席のチケットホルダーにお入れください。</li>
                        <Divider />
                        <li>文化センター駐車場をご利用のお客様は、駐車券をお持ちの上、ホールホワイエにございます駐車券認証機にて認証をされますと、最大で４時間までの駐車料金無料サービスをご利用いただけます。</li>
                        <Divider />
                        <li>当日お配りするパンフレットに演奏会アンケートのQRコードを記載いたします。アンケートへのご協力をよろしくお願いいたします。</li>
                        <Divider />
                        <li>未就学児のご入場はご遠慮ください。</li>
                        <Divider />
                    </ol>
                </div>
                <div className='hands-up-welcome'>
                    <p>皆様のご来場を心よりお待ち申し上げます。</p>
                </div>
                
            </Container>
        </div>
    )
}

export default HandsUp