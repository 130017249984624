import React from 'react'
import yasuoshinozaki from "../../images/yasuoshinozaki.jpg"
import "./Conductor.css"

const Conductor = () => {
    return (
        <div className='conductor-section'>
            <div className='image-container'>
                <img src={yasuoshinozaki} alt="篠﨑 靖男" />
                <p>©︎BenjaminEalovega</p>
            </div>
            <div className="text">
                <h2 className="name">篠﨑 靖男 (指揮)</h2>
                <div className="description">
                    桐朋学園大学卒業。1993年ペドロッティ国際指揮者コンクール最高位。ウィーン国立音楽大学で研鑽を積み、2000年シベリウス国際指揮者コンクールで第2位を受賞し、ヘルシンキ･フィルを指揮してヨーロッパにデビュー。<br />
                    2001年より2004年までロサンゼルス･フィルの副指揮者を務めた後ロンドンに本拠を移し、ロンドン･フィル、BBCフィル、フランクフルト放送響、ボーンマス響、フィンランド放送響、スウェーデン放送響、ドイツ･マグデブルク・フィル、南アフリカ共和国のKZNフィル、ヨハネスブルグ･フィル、ケープタウン･フィルなど、日本国内はもとより各国の主要オーケストラを指揮。2007年から2014年7月に勇退するまで7年半、フィンランド･キュミ･シンフォニエッタの芸術監督･首席指揮者としてオーケストラの目覚しい発展を支え、2014年9月から2018年3月まで静岡響のミュージック･アドバイザーと常任指揮者を務めるなど、国内外で活躍を続けている。現在、桐朋学園大学音楽学部非常勤講師（指揮専攻）として後進の指導にも当たっている。
                </div>
                <div className='link-container'>
                    <a href='http://www.yasuoshinozaki.com/' className='link' target="_blank">
                        篠﨑靖男オフィシャルHP
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Conductor